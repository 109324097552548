import React from "react";
import theme from "theme";
import { Theme, Link, Text, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"404"} />
		<Helmet>
			<title>
				Recharged - A journey to reconnect
			</title>
			<meta name={"description"} content={"Recharged is a cozy survival game where you navigate wildlands, overcome inner struggles, and find harmony through nature. Forage, farm, fight, and thrive—all at your own pace. There is no grand mission - just focus on surviving, exploring, and being yourself."} />
			<meta property={"og:title"} content={"Recharged - A journey to reconnect"} />
			<meta property={"og:description"} content={"Recharged is a cozy survival game where you navigate wildlands, overcome inner struggles, and find harmony through nature. Forage, farm, fight, and thrive—all at your own pace. There is no grand mission - just focus on surviving, exploring, and being yourself."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6754cbc29335410018c86ba6/images/Image%20Sequence_004_0031.png?v=2024-12-07T22:32:33.133Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6754cbc29335410018c86ba6/images/recharged_favicon.png?v=2024-12-08T02:27:11.857Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6754cbc29335410018c86ba6/images/recharged_favicon.png?v=2024-12-08T02:27:11.857Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6754cbc29335410018c86ba6/images/recharged_favicon.png?v=2024-12-08T02:27:11.857Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6754cbc29335410018c86ba6/images/recharged_favicon.png?v=2024-12-08T02:27:11.857Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6754cbc29335410018c86ba6/images/recharged_favicon.png?v=2024-12-08T02:27:11.857Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6754cbc29335410018c86ba6/images/recharged_favicon.png?v=2024-12-08T02:27:11.857Z"} />
			<meta name={"msapplication-TileColor"} content={"#F2EFEA"} />
		</Helmet>
		<Components.Header />
		<Section
			padding="100px 40px 100px 40px"
			quarkly-title="HeroBlock"
			background="rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/6754cbc29335410018c86ba6/images/Image%20Sequence_004_0031.png?v=2024-12-07T22:32:33.133Z) center/cover repeat scroll padding-box"
			sm-padding="100px 20px 100px 20px"
			min-height="100vh"
			sm-background="rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/6754cbc29335410018c86ba6/images/Image%20Sequence_004_0031.png?v=2024-12-07T22:32:33.133Z) 30%/cover repeat scroll padding-box"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				grid-gap="40px"
				max-width="none"
				width="100%"
				sm-flex-direction="column"
				min-height="80vh"
				md-flex-direction="column"
			/>
			<Box
				display="flex"
				grid-template-columns="1fr 1fr"
				grid-gap="8px"
				sm-grid-template-columns="1fr"
				flex-direction="column"
				width="100%"
				align-items="flex-end"
				justify-content="flex-start"
				padding="80px 0px 0px 0px"
				sm-align-items="flex-start"
			>
				<Text
					margin="0px 0px 0px 0"
					color="--light"
					font="--headline1"
					width="60%"
					lg-font="--headline1Lg"
					sm-font="--headline2Lg"
					sm-width="100%"
					text-transform="uppercase"
					text-align="right"
				>
					Page not found
				</Text>
				<Text
					margin="0px 0px 24px 0"
					color="--light"
					sm-text-align="left"
					font="--headline3"
					text-align="right"
					lg-font="--headline3Lg"
					sm-width="100%"
					text-transform="uppercase"
					width="60%"
				>
					Sorry, we couldn’t find the page you’re looking for.
				</Text>
				<Box display="flex" flex-direction="column" align-items="flex-start">
					<Link
						font="--base"
						flex-direction="row-reverse"
						padding="8px 24px 8px 24px"
						background="--color-indigo"
						color="--light"
						display="flex"
						text-align="right"
						align-items="center"
						justify-content="flex-end"
						text-transform="uppercase"
						letter-spacing="1px"
						border-radius="8px"
						text-decoration-line="initial"
						hover-background="--color-darkL1"
					>
						Go back to home
					</Link>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		
		<RawHtml>
			<style place={"endOfHead"} rawKey={"60da14fa3f4eb1001ea27687"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});